import React from "react";
import jcbPicture from "./jcb19c-1e.jpg";
import "./App.scss";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div className="app">
      <div className="scrollable">
        <header className="company-name">
          <div className="container text-center">
            <h1>CS2L AB</h1>
            <h3>
              <p className="lead"> Rentals in Skåne </p>
            </h3>
          </div>
        </header>
        <div className="container equipment" id="equipment-view">
          <div className="jcb">
            <div className="row">
              <h2 className="col-sm title">
                19C-1E ELECTRIC COMPACT EXCAVATOR
              </h2>
            </div>
            <div className="row">
              <div className="col-sm picture-container">
                <img
                  src={jcbPicture}
                  className="col-sm picture"
                  alt="JCB 19C-1E Electric Excavator"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm fact">
                <div className="title">Operating Weight</div>
                <div className="number">1902 kg</div>
                <div className="number">(4193 lb)</div>
              </div>
              <div className="col-sm fact">
                <div className="title">Max Dig Depth</div>
                <div className="number">2.82 m</div>
                <div className="number">(9 ft 3 in)</div>
              </div>
              <div className="col-sm fact">
                <div className="title">Max Dump Height</div>
                <div className="number">2.82 m</div>
                <div className="number">(9ft 3 in)</div>
              </div>
            </div>
            <div className="row cta">
              <div className="col-sm">
                <a
                  className="btn btn-primary btn-lg"
                  href="mailto:booking@cs2l.se?subject=Booking Enquiry"
                >
                  Send Booking Enquiry
                </a>
              </div>
            </div>
            <div className="row pricing">
              <h3>Our Pricing (Inc 25% VAT)</h3>
              <div className="col-sm fact">
                <div className="title">1 Day</div>
                <div className="number">1800kr</div>
                <div className="number">(1800kr/day)</div>
              </div>
              <div className="col-sm fact">
                <div className="title">3 Days</div>
                <div className="number">4300kr</div>
                <div className="number">(1433kr/day)</div>
              </div>
              <div className="col-sm fact">
                <div className="title">1 Week</div>
                <div className="number">8200kr</div>
                <div className="number">(1171kr/day)</div>
              </div>
            </div>
          </div>
          <div className="row details">
            <div className="col-sm">
              <h3> Why pay for fuel? </h3>
              <p>
                Our equipment is rented fully charged and is returned empty. The
                excavator is rented with 3 buckets (all tiltable). As the
                excavator is zero emissions it can be used without issue
                indoors. Machine can be picked up on a hydraulic dump trailer
                (3500kg BE Drivers licence required) or delivered for a fee. The
                battery lasts through a full working day and the machine is
                almost silent when running allowing for late working hours and
                easy discussions while operating. The excavator has equivilant
                power to the JCB 19C Diesel variant and other 2 ton excavators.
              </p>

              <p>
                Delivery is available to Skurup, Ystad and surrounding areas for
                a reasonable price.
              </p>

              <p>
                Need the excavator for longer or planning to rent often?{" "}
                <a href="mailto:info@cs2l.se">
                  Contact us for discounted pricing!
                </a>
              </p>
            </div>
          </div>
        </div>

        <p className="footer">
          <div>CS2L | Villie 432 Rydsgård</div>
          <div>A part of L.A.U. Group AB</div>
          <p className="contact" id="contact-view">
            Contact: <a href="mailto:info@cs2l.se">info@cs2l.se</a>
          </p>
        </p>
      </div>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
